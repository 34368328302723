
	// Spacing helpers
	// Generating classes to manipulate spacing
	@import '../../../modules/--Config/breakpoints';
	@import '../../../stylesheets/prod/vars';

			@each $breakpoint in $breakpoints{

				/* #{$breakpoint} */

				@media #{nth($breakpoint, 3)}{ 
					
					@for $i from 1 through 300{
						
						// Margin

						.Margin-#{$i}-#{nth($breakpoint, 1)}{
							margin:#{$i}px!important;
						}
						.MarginVertical-#{$i}-#{nth($breakpoint, 1)}{
							margin-top:#{$i}px!important;
							margin-bottom:#{$i}px!important;
						}
						.MarginHorizontal-#{$i}-#{nth($breakpoint, 1)}{
							margin-left:#{$i}px!important;
							margin-right:#{$i}px!important;
						}
						.MarginTop-#{$i}-#{nth($breakpoint, 1)}{
							margin-top:#{$i}px!important;
						}
						.MarginRight-#{$i}-#{nth($breakpoint, 1)}{
							margin-right:#{$i}px!important;
						}
						.MarginLeft-#{$i}-#{nth($breakpoint, 1)}{
							margin-left:#{$i}px!important;
						}
						.MarginBottom-#{$i}-#{nth($breakpoint, 1)}{
							margin-bottom:#{$i}px!important;
						}

						// Padding

						.Padding-#{$i}-#{nth($breakpoint, 1)}{
							padding:#{$i}px;
						}
						.PaddingVertical-#{$i}-#{nth($breakpoint, 1)}{
							padding-top:#{$i}px!important;
							padding-bottom:#{$i}px!important;
						}
						.PaddingHorizontal-#{$i}-#{nth($breakpoint, 1)}{
							padding-left:#{$i}px!important;
							padding-right:#{$i}px!important;
						}
						.PaddingTop-#{$i}-#{nth($breakpoint, 1)}{
							padding-top:#{$i}px;
						}
						.PaddingRight-#{$i}-#{nth($breakpoint, 1)}{
							padding-right:#{$i}px;
						}
						.PaddingLeft-#{$i}-#{nth($breakpoint, 1)}{
							padding-left:#{$i}px;
						}
						.PaddingBottom-#{$i}-#{nth($breakpoint, 1)}{
							padding-bottom:#{$i}px;
						}
						
					} // loop through 1 to 300
					
					.NoMarginTop-#{nth($breakpoint, 1)}{
						margin-top:0!important;
					}
					.NoMarginLeft-#{nth($breakpoint, 1)}{
						margin-left:0!important;
					}
					.NoMarginRight-#{nth($breakpoint, 1)}{
						margin-right:0!important;
					}
					.NoMarginBottom-#{nth($breakpoint, 1)}{
						margin-right:0!important;
					}
					.NoMargin-#{nth($breakpoint, 1)}{
						margin:0!important;
					}

					.NoPaddingTop-#{nth($breakpoint, 1)}{
						padding-top:0!important;
					}
					.NoPaddingLeft-#{nth($breakpoint, 1)}{
						padding-left:0!important;
					}
					.NoPaddingRight-#{nth($breakpoint, 1)}{
						padding-right:0!important;
					}
					.NoPaddingBottom-#{nth($breakpoint, 1)}{
						padding-right:0!important;
					} 
					.NoPadding-#{nth($breakpoint, 1)}{
						padding:0!important;
					}

				} // media query

			} // each breakpoint
			
		
			 